import React from 'react'
import './good.css'

const Good = (props) => {
    const selected = props.selected
    return(
        
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" className={(selected === true) ? "r-selected" : ""}>
            <g id="smiley_middle_good" data-name="smiley middle good" transform="translate(-387 -870)">
            <circle id="good" data-name="Ellipse 9" cx="13" cy="13" r="13" transform="translate(387 870)" fill="#B2B2B2"/>
            <g id="Groupe_46" data-name="Groupe 46" transform="translate(0 2)">
                <ellipse id="Ellipse_10" data-name="Ellipse 10" cx="1" cy="3" rx="1" ry="3" transform="translate(392 876)" fill="#fff"/>
                <ellipse id="Ellipse_11" data-name="Ellipse 11" cx="1" cy="3" rx="1" ry="3" transform="translate(405 876)" fill="#fff"/>
            </g>
            <path id="Tracé_21" data-name="Tracé 21" d="M393,886.567c-.025.365,9.049,2.822,14.228,0C407.359,886.5,403.607,887.4,393,886.567Z" transform="translate(0 2)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default Good