import React, { Component } from 'react'
import {Container,Row,Col} from 'react-bootstrap'
import './StepTwo.css'
import { FORM_ACTIONS } from '../../../../constants/actionsNames';
import HeadTitle from '../../../../components/HeadTitle'
import InputBox from '../../../../components/InputBox'
import ImputRadioBox from '../../../../components/ImputRadioBox'
import SelectaImage from '../../../../components/SelectaImage'
import Selecta from '../../../../components/Selecta'
import InputButton from '../../../../components/InputButton'

import SurveyHelper from '../../../../Helpers/SurveyHelper'

import {ItemsCompta} from '../../../../constants/imgItems'
import ItemsStepTwo from '../../../../constants/itemsStepTwo'
import leftArrow from '../../../../assets/svg/left-arrow.svg'
import rightArrow from '../../../../assets/svg/right-arrow.svg'


class StepTwo extends Component {
    constructor (props) {
        super(props)
        this.state = {
            accountingInformations: {
                ...this.props.data
            }
        }
    }

    

    hideOrShowContent = (selector, testValue, trueValue) => {
        let selectorObj = document.querySelector(selector)

        if(testValue === trueValue){
            if(selectorObj.classList.contains('none')){
                selectorObj.classList.remove('none') 
            }else{
                selectorObj.classList.add('none') 
            }
            
            
        }else{
            if(!selectorObj.classList.contains('none')){
                selectorObj.classList.add('none') 
            }
        }
        return selectorObj
    }

    parsing = (value, base) => {
        let parsed = parseInt(value, base);
        if (isNaN(parsed)) { return 0 }
        return parsed
    }

    handleNext = () => {
        if (this.props.handleStepTwo)
            this.props.handleStepTwo(this.state.accountingInformations, FORM_ACTIONS.NEXT)
    }

    handlePrev = () => {
        if (this.props.handleStepTwo)
            this.props.handleStepTwo(this.state.accountingInformations, FORM_ACTIONS.PREVIOUS)
    }


    handleHasAccounting = (value) => {
        let SurveyHelp = new SurveyHelper()
        SurveyHelp.hideOrShowContent('.row-2',value,'oui')
        SurveyHelp.hideOrShowContent('.row-3',value,'oui')
        SurveyHelp.hideOrShowContent('.row-4',value,'oui')
        SurveyHelp.hideOrShowContent('.row-5',value,'oui')
        SurveyHelp.hideOrShowContent('.row-6',value,'oui')
        SurveyHelp.hideOrShowContent('.row-7',value,'non')

        this.setState({
            accountingInformations: {
            ...this.state.accountingInformations,
            comptabilite_comptabiliteInterne: value
          }
        })
    }

    handleHasAccounting2 = (value) => {
        value = this.parsing(value, 10)
        this.setState({
            accountingInformations: {
            ...this.state.accountingInformations,
            comptabilite_nombreEmploye: value
          }
        })
    }

    handleHasRelationship = (value) => {
        this.setState({
            accountingInformations: {
            ...this.state.accountingInformations,
            comptabilite_relationAvecCabinetComptable: value
          }
        })
    }

    handleIsUsingAccounting = (value) => {
        this.setState({
            accountingInformations: {
            ...this.state.accountingInformations,
            comptabilite_usageLogicielComptable: value
          }
        })
    }

    handleAccountingSoftwares = (value) => {
        this.setState({
            accountingInformations: {
            ...this.state.accountingInformations,
            comptabilite_logicielsComptableUtilises: value.toString()
          }
        })
    }

    handleAccountingInputFreqeuncy = (value) => {
        this.setState({
            accountingInformations: {
            ...this.state.accountingInformations,
            comptabilite_frequenceSaisiseComptable: value
          }
        })
    }

    handleMonthlyTreated = (value) => {
        this.setState({
            accountingInformations: {
            ...this.state.accountingInformations,
            comptabilite_piecesComptableMensuellesTraites: value
          }
        })
    }
    
    handleMinimumTimeForAccounting = (value) => {
        this.setState({
            accountingInformations: {
            ...this.state.accountingInformations,
            comptabilite_tempsMinimunSaisieComptable: value
          }
        })
    }

    handleIsDematerialExchange = (value) => {
        this.setState({
            accountingInformations: {
             ...this.state.accountingInformations,
             comptabilite_echangesCabinetDematerialises: value
           }
        });
    }

    componentDidMount(){
        const {hasSelfAccountingService} = this.state.accountingInformations

        if(hasSelfAccountingService){
            this.handleHasAccounting(hasSelfAccountingService) 
        
        }
    }

    render () {
        const {comptabilite_logicielsComptableUtilises,comptabilite_frequenceSaisiseComptable,comptabilite_piecesComptableMensuellesTraites,comptabilite_comptabiliteInterne,comptabilite_relationAvecCabinetComptable,comptabilite_usageLogicielComptable,comptabilite_nombreEmploye,comptabilite_echangesCabinetDematerialises,comptabilite_tempsMinimunSaisieComptable} = this.state.accountingInformations
        const subOne = <InputBox statement={'Combien d’employés à ce service ?'} type={'number'} getResult={this.handleHasAccounting2} value={comptabilite_nombreEmploye} lighterBg  className={'p-start fade-in-top-light animation-fill-mode-both'}  />
        let itemsImg = ItemsCompta
        const subThree = <SelectaImage statement={<span>Lesquels ? <small className="greenly"><i>(plusieurs choix sont possible)</i></small> </span>} items={itemsImg} getResult={this.handleAccountingSoftwares} preventValue={comptabilite_logicielsComptableUtilises.split(',')} multiple others lighterBg className={'p-start fade-in-top-light animation-fill-mode-both'} />
        const {four,five,six} = ItemsStepTwo
        const q3 = <span>Utilisation de logiciel de comptabilité ? </span>
        const lastq = <span>Est-ce que les échanges avec votre cabinet sont dématérialisés ? </span>
        
        return(
            <Container className="step-two">
                <Row>
                    <Col>
                        <HeadTitle title={'GESTION DE LA SAISIE COMPTABLE'} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ImputRadioBox statement={'Comptabilité tenue en interne ?'} other as={subOne} getResult={this.handleHasAccounting} preventValue={comptabilite_comptabiliteInterne} />
                    </Col>
                </Row>
                <Row className="row-2 none">
                    <Col>
                        <ImputRadioBox statement={'Relation avec un cabinet comptable pour la révision ?'} getResult={this.handleHasRelationship}  preventValue={comptabilite_relationAvecCabinetComptable} />
                    </Col>
                </Row>
                <Row className="row-3 none">
                    <Col>
                        <ImputRadioBox statement={q3} other as={subThree} getResult={this.handleIsUsingAccounting}  preventValue={comptabilite_usageLogicielComptable} />
                    </Col>
                </Row>
                <Row className="row-4 none">
                    <Col>
                        <Selecta statement={'Fréquence des saisies comptables ?'} items={four} getResult={this.handleAccountingInputFreqeuncy} preventValue={comptabilite_frequenceSaisiseComptable} />
                    </Col>
                </Row>
                <Row className="row-5 none">
                    <Col>
                        <Selecta statement={'Nombre des pièces comptables à traiter par mois ?'} items={five} getResult={this.handleMonthlyTreated} preventValue={comptabilite_piecesComptableMensuellesTraites} />
                    </Col>
                </Row>
                <Row className="row-6 none">
                    <Col>
                        <Selecta statement={'Temps minimum mensuel passé pour les saisies comptables ?'} items={six} getResult={this.handleMinimumTimeForAccounting} preventValue={comptabilite_tempsMinimunSaisieComptable} />
                    </Col>
                </Row>
                <Row className="row-7 none">
                    <Col>
                        <ImputRadioBox statement={lastq} getResult={this.handleIsDematerialExchange}  preventValue={comptabilite_echangesCabinetDematerialises} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="actions-wrapper">
                            <InputButton id={'prevtwo'} name={'prevtwo'} value={'PRECEDENT'} leftIcon={leftArrow} getResult={this.handleOne} onClick={this.handlePrev} />
                            <InputButton id={'nexttwo'} name={'nexttwo'} value={'SUIVANT'} rightIcon={rightArrow} getResult={this.handleOne} onClick={this.handleNext} />
                        </div>
                    </Col>
                </Row>
                
            </Container>
        )
    }
}

export default StepTwo