const ItemsStepFive = {
    two: [
        {
            type: 'text',
            content: 'Gain de temps',
            value: 'Gain de temps',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: ''
        },
        {
            type: 'text',
            content: 'Économie de coûts ',
            value: 'Économie de coûts ',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: ''
        },
        {
            type: 'text',
            content: 'Fiabilité',
            value: 'Fiabilité',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: ''
        },
        {
            type: 'text',
            content: 'Réaffectation des ressources',
            value: 'Réaffectation des ressources',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: ''
        }
    ],
    three: [
        {
            type: 'text',
            content: 'Achat',
            value: 'Achat',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: true,
            popOverDescription: 'Commandes, saisie des factures…'
        },
        {
            type: 'text',
            content: 'Vente',
            value: 'Vente',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: true,
            popOverDescription: 'Devis, emission factures…'
        },
        {
            type: 'text',
            content: 'Trésorerie',
            value: 'Trésorerie',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: true,
            popOverDescription: 'Encaissements, décaissements, prévisionnel, banque...'
        },
        {
            type: 'text',
            content: 'Social',
            value: 'Social',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: true,
            popOverDescription: 'Gestion de la paie, déclarations sociales'
        },
        {
            type: 'text',
            content: 'Impôts',
            value: 'Impôts',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: true,
            popOverDescription: 'Déclarations TVA, taxes, acomptes IS, précompte loyer…'
        },
        {
            type: 'text',
            content: 'Documents',
            value: 'Documents',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: true,
            popOverDescription: 'Classification des documents de la société, archivage'
        },
    ],
    four: [
        {
            type: 'text',
            content: '5 000 - 10 000',
            value: '5 000 - 10 000',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: ''
        },
        {
            type: 'text',
            content: '10 000 - 20 000',
            value: '10 000 - 20 000',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: ''
        },
        {
            type: 'text',
            content: '20 000 - 50 000',
            value: '20 000 - 50 000',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: ''
        },
        {
            type: 'text',
            content: 'Plus de 50 000',
            value: 'Plus de 50 000',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: ''
        },
    ],
    five: [
        {
            type: 'text',
            content: 'Je m’inquiète de la sécurité de mes données comptables',
            value: 'Je m’inquiète de la sécurité de mes données comptables',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: ''
        },
        {
            type: 'text',
            content: 'Je n’ai aucune appréhension si des garanties de protection sont présentées',
            value: 'Je n’ai aucune appréhension si des garanties de protection sont présentées',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: ''
        }
    ]
    
}

export default ItemsStepFive