import ciel from '../assets/svg/ciel.svg'
import computer from '../assets/svg/computer.svg'
import excel from '../assets/svg/Excel.svg'
import gmail from '../assets/svg/gmail.svg'
import jira from '../assets/svg/jira.svg'
import sage from '../assets/svg/sage.svg'
import sageCloud from '../assets/svg/sagecloud.svg'
import scanner from '../assets/svg/scanner.svg'
import smartphone from '../assets/svg/smartphone.svg'
import leftArrow from '../assets/svg/left-arrow.svg'
import rightArrow from '../assets/svg/right-arrow.svg'
import dotArrowRight from '../assets/svg/arrow-dots-right.svg'
import save from '../assets/svg/save.svg'

const ItemsCompta = [
    {
        type: 'image',
        content: ciel,
        value: 'ciel',
        params: {
            width: '85',
            height: '85',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'image',
        content: excel,
        value: 'excel',
        params: {
            width: '85',
            height: '85',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'image',
        content: sage,
        value: 'sage',
        params: {
            width: '85',
            height: '85',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    
]

const ItemsPaie = [
    {
        type: 'image',
        content: ciel,
        value: 'ciel',
        params: {
            width: '85',
            height: '85',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'image',
        content: excel,
        value: 'excel',
        params: {
            width: '85',
            height: '85',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'image',
        content: sage,
        value: 'sage',
        params: {
            width: '85',
            height: '85',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    
]

const ItemsCloud = [
    {
        type: 'image',
        content: gmail,
        value: 'gmail',
        params: {
            width: '95',
            height: '95',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'image',
        content: jira,
        value: 'jira',
        params: {
            width: '95',
            height: '95',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'image',
        content: sageCloud,
        value: 'sage cloud',
        params: {
            width: '95',
            height: '95',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    
]

const ItemsDevice = [
    {
        type: 'image',
        content: smartphone,
        value: 'smartphone',
        params: {
            width: '95',
            height: '95',
        },
        selected: false,
        popOver: true,
        popOverDescription: 'Telephone'
    },
    {
        type: 'image',
        content: computer,
        value: 'computer',
        params: {
            width: '95',
            height: '95',
        },
        selected: false,
        popOver: true,
        popOverDescription: 'Ordinateur'
    },
    {
        type: 'image',
        content: scanner,
        value: 'scanner',
        params: {
            width: '95',
            height: '95',
        },
        selected: false,
        popOver: true,
        popOverDescription: 'Scanner'
    },
    
]

export {ItemsCompta,ItemsPaie,ItemsCloud,ItemsDevice,dotArrowRight,leftArrow,rightArrow,save}