import React, {Component} from 'react'

import './Welcome.css'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Start from '../welcome/start'
import End from '../welcome/end'


class Welcome extends Component{

    constructor(props){
        super(props)

        this.state = {
            position: this.props.position
        }
    }

    handleClick = () => {
    }

    setPosition = () => {
        const {position} = this.state
        
        if(position && position === 'end'){
            return (
                <End history={this.props.history}/>
            )
        }
        return (
            <Start history={this.props.history}/>
        )
    }

    render(){

        return(
            <div className="welcome">
                <Header />
                {
                    this.setPosition()
                }
                <Footer />
            </div>
        )
    }
}

export default Welcome