const ItemsStepFour = {
    two: [
        {
            type: 'text',
            content: 'Faible',
            value: 'Faible',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: true,
            popOverDescription: 'peu d’utilisation des outils informatiques'
        },
        {
            type: 'text',
            content: 'Intermédiaire',
            value: 'Intermédiaire',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: true,
            popOverDescription: 'Utilisation de logiciels de base, comme MS Word, MS Excel ...'
        },
        {
            type: 'text',
            content: 'Avancée',
            value: 'Avancée',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: true,
            popOverDescription: 'utilisation d’outils de productivité au quotidien'
        }
    ],
    
}

export default ItemsStepFour