import { SET_STEP, SET_STEPS, SET_STEP_DATA } from '../constants/actions-types'

export function setCurrentStep (payload) {
    return {
        type: SET_STEP,
        payload
    }
}

export function setSteps (payload) {
    return {
        type: SET_STEPS,
        payload
    }
}

export function setStepData (payload, notify) { // payload : {step, data}
    return (dispatch) => {
        dispatch({
            type: SET_STEP_DATA,
            payload
        })
        notify()
    }
}


