import React, { Component } from 'react'
import {Container,Row,Col} from 'react-bootstrap'

import './StepFour.css'
import { FORM_ACTIONS } from '../../../../constants/actionsNames';
import HeadTitle from '../../../../components/HeadTitle'
import InputBox from '../../../../components/InputBox'
import ImputRadioBox from '../../../../components/ImputRadioBox'
import SelectaImage from '../../../../components/SelectaImage'
import Selecta from '../../../../components/Selecta'
import InputButton from '../../../../components/InputButton'

import {ItemsDevice,ItemsCloud} from '../../../../constants/imgItems'
import ItemsStepFour from '../../../../constants/ItemsStepFour'
import leftArrow from '../../../../assets/svg/left-arrow.svg'
import rightArrow from '../../../../assets/svg/right-arrow.svg'

class StepFour extends Component {
    constructor (props) {
        super(props)
        this.state = {
            useOfInternetAndTechnologies: {
                ...this.props.data
            }
        }
    }

    handleNext = () => {
        if (this.props.handleStepFour)
            this.props.handleStepFour(this.state.useOfInternetAndTechnologies, FORM_ACTIONS.NEXT)
    }

    handlePrev = () => {
        if (this.props.handleStepFour)
            this.props.handleStepFour(this.state.useOfInternetAndTechnologies, FORM_ACTIONS.PREVIOUS)
    }

    handleKindOfMedias = (value) => {
        this.setState({
            useOfInternetAndTechnologies: {
            ...this.state.useOfInternetAndTechnologies,
            internet_appareilsUtilises: value.toString(),
          }
        })
    }

    handleCompanyDigital = (value) => {
        
        this.setState({
            useOfInternetAndTechnologies: {
            ...this.state.useOfInternetAndTechnologies,
            internet_maturiteDigitaleEntreprise: value
          }
        })
    }

    handleCloudsTools = (value) => {
        console.log("Value Cloud tools: ", value);
        this.setState({
            useOfInternetAndTechnologies: {
                ...this.state.useOfInternetAndTechnologies,
                internet_outilsCloudUtilises: value.toString(),
            }
        });
    }

    handleIsUsingCloudTool = (value) => { 
        this.setState({
            useOfInternetAndTechnologies: {
            ...this.state.useOfInternetAndTechnologies,
            internet_utiliseUnOutilCloud: value
          }
        })
    }

    handleInternetAccess = (value) => {
        
        this.setState({
            useOfInternetAndTechnologies: {
            ...this.state.useOfInternetAndTechnologies,
            internet_accesInternetEntreprise: value
          }
        })
    }

    handleHasOnlineProcedure = (value) => {
        this.setState({
            useOfInternetAndTechnologies: {
            ...this.state.useOfInternetAndTechnologies,
            internet_usageProcedureEnLigne: value
            }
        })
    }

    handleOnlineProcedures = (value) => {
        this.setState({
            useOfInternetAndTechnologies: {
            ...this.state.useOfInternetAndTechnologies,
            internet_proceduresEnLigne: value
          }
        })
    }

    formatStrToArray = (value) => {
        if(value === undefined) return "";
        if(value === "") return "";
        if(value.length > 0) { return value.split(','); }
    }

    render () {
        let {internet_appareilsUtilises,internet_maturiteDigitaleEntreprise,internet_outilsCloudUtilises,internet_accesInternetEntreprise,internet_usageProcedureEnLigne,internet_proceduresEnLigne,internet_utiliseUnOutilCloud} = this.state.useOfInternetAndTechnologies
        let itemsImgDevice = ItemsDevice
        let {two} = ItemsStepFour
        let itemsImgCompta = ItemsCloud
        const subThree = <SelectaImage statement={<span>Lesquels ? <small className="bluely"><i>(plusieurs choix sont possible)</i></small> </span>} items={itemsImgCompta} getResult={this.handleCloudsTools} multiple others lighterBg preventValue={internet_outilsCloudUtilises.split(",")} className={'p-start fade-in-top-light animation-fill-mode-both'}  />
        const subSix = <InputBox statement={'Lequel ?'} value={internet_proceduresEnLigne} getResult={this.handleOnlineProcedures} lighterBg  className={'p-start fade-in-top-light animation-fill-mode-both'}  />
        const q1 = <span>Sur votre lieu de travail, possédez-vous ? <small className="bluely"><i>(plusieurs choix sont possible)</i></small></span>
        const q3 = <span>Utilisez-vous des outils cloud stockés sur le cloud ?</span>
        const q5 = <span>Dans le cadre de vos activités, utilisez-vous les procédures en ligne <small className="bluely"><i>(Télédéclarations des impôts, CNPS)</i></small> ?</span>
        console.log("STATE ETAPE 4", this.state);
        return(
            <Container className="step-two">
                <Row>
                    <Col>
                        <HeadTitle title={'ANALYSE DE L’UTILISATION D’INTERNET ET DES APPLICATIONS'} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SelectaImage statement={q1} items={itemsImgDevice} getResult={this.handleKindOfMedias} multiple preventValue={internet_appareilsUtilises.split(',')} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Selecta statement={'Comment évaluez-vous la maturité digitale de votre entreprise ?'} items={two} getResult={this.handleCompanyDigital} preventValue={internet_maturiteDigitaleEntreprise} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ImputRadioBox statement={q3} other as={subThree} getResult={this.handleIsUsingCloudTool}  preventValue={internet_utiliseUnOutilCloud} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ImputRadioBox statement={'Avez-vous un accès à internet dans votre entreprise ?'} getResult={this.handleInternetAccess} preventValue={internet_accesInternetEntreprise}  />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ImputRadioBox statement={q5} other as={subSix} getResult={this.handleHasOnlineProcedure} preventValue={internet_usageProcedureEnLigne}  />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="actions-wrapper">
                            <InputButton id={'prevfour'} name={'prevfour'} value={'PRECEDENT'} leftIcon={leftArrow} onClick={this.handlePrev} />
                            <InputButton id={'nextfour'} name={'nextfour'} value={'SUIVANT'} rightIcon={rightArrow} onClick={this.handleNext} />
                        </div>
                    </Col>
                </Row>
            </Container>
            
        )
    }
}

export default StepFour