import React, {Component} from 'react'
import {Row,Col} from 'react-bootstrap'

import './InputBox.css'

class InputBox extends Component{

    constructor(props){
        super(props)

        this.state = {
            statement: this.props.statement,
            type: this.props.type,
            value:'',
            lighterBg: this.props.lighterBg,
            className: this.props.className
        }
       
    }

    handleChange = ({target: {value}}) => {
       let {getResult} = this.props

        this.setState({
            value,
        })

        getResult(value)
    }



    render(){
        const {statement, lighterBg} = this.state
        const {value,type,className} = this.props
        return(
            <Row className={lighterBg ? "inputbox lighter-bg " + className : "inputbox " + className}>
                <Col md={7} sm={12}>
                    <div className="statement">{statement}</div>
                    <input type={type ? type : "text"} value={value} onChange={this.handleChange} className="txt-input" />
                </Col>
            </Row>
        )
    }
}

export default InputBox