import smileyVeryGood from '../assets/svg/smiley-very-good.svg'
import smileyMiddleGood from '../assets/svg/smiley-middle-good.svg'
import smileybad from '../assets/svg/smiley-bad.svg'

const Smiley = {
    verygood: smileyVeryGood,
    good: smileyMiddleGood,
    bad: smileybad
}

export default Smiley