import React, {Component} from 'react'
import {Popover,OverlayTrigger} from 'react-bootstrap'


import './SelectaItem.css'

class SelectaItem extends Component {
    constructor(props){
        super(props)

        this.state = {
            index: this.props.index,
            type: this.props.obj.type,
            content: this.props.obj.content,
            value: this.props.obj.value,
            params: {
                width: this.props.obj.width,
                height: this.props.obj.height
            },
            isSelected: this.props.obj.isSelected,
            popOver: this.props.obj.popOver,
            popOverDescription: this.props.obj.popOverDescription
        }
      
    }

    // static propTypes = {
    //     setitem: PropTypes.func.isRequired,
    // }

    handleSelect = () => {
        const {isSelected, index} = this.state
        this.setState({
           isSelected: !isSelected
        },() => {
            const {setitem} = this.props
            const obj = this.state
            setitem(obj, index)
        })
       
    }

    render(){
        const {value, content, index, isSelected, popOver, popOverDescription} = this.state
        if(popOver === true){
            const pop = (
                <Popover id="popover-basic" className="pop-over">
                    {popOverDescription}
                </Popover>
            );
            return(
                <div className="selectaItem">
                    <OverlayTrigger trigger="hover" placement="bottom" overlay={pop}>
                        <button type="button" value={value} index={index} className={isSelected ? 's-selected' : ''} onClick={this.handleSelect}>
                            {content}
                        </button>
                    </OverlayTrigger>
                </div>
            )
        }
        return(
            <div className="selectaItem">
                <button type="button" value={value} index={index} className={isSelected ? 's-selected' : ''} onClick={this.handleSelect}>
                    {content}
                </button>
            </div>
        )
        
    }
}

export default SelectaItem