import { SET_STEP, SET_STEPS, SET_STEP_DATA } from "../constants/actions-types";

const initialState = {
    
    form: {
        companyIdentification: {
            identification_denomination: '',
            identification_email: '',
            identification_formeLegale: '',
            identification_estCabinetComptable: '',
            identification_anciennete: '',
            identification_secteurActivite: '',
            identification_numbreEmploye: '',
            identification_chiffreAffaire: '',
            identification_depenseMansuelleComptaPaie: '',
            identification_coutAnnuelDsf: ''
        },
        accountingInformations: {
            comptabilite_comptabiliteInterne: '',
            comptabilite_nombreEmploye: '',
            comptabilite_relationAvecCabinetComptable: '',
            comptabilite_usageLogicielComptable: '',
            comptabilite_logicielsComptableUtilises: '',
            comptabilite_frequenceSaisiseComptable: '',
            comptabilite_piecesComptableMensuellesTraites: '',
            comptabilite_tempsMinimunSaisieComptable: '',
            comptabilite_echangesCabinetDematerialises: '',
        },
        pay: {
            paie_gestionPaieInterne: '',
            paie_nombreEmployes: '',
            paie_relationAvecCabinetComptable: '',
            paie_usageLogicielComptable: '',
            paie_logicielDePaieUtilises: '',
            paie_echangesCabinetDematerialises: '',
        },
        useOfInternetAndTechnologies: {
            internet_appareilsUtilises: '',
            internet_maturiteDigitaleEntreprise: '',
            internet_utiliseUnOutilCloud: '',
            internet_outilsCloudUtilises: '',
            internet_accesInternetEntreprise: '',
            internet_usageProcedureEnLigne: '',
            internet_proceduresEnLigne: '',
        },
        newSoftwareOverview: {
            solution_niveauInteretAppCloud: '',
            solution_satisfactionProcessusComptable: '',
            solution_satisfactionProcessusComptableObservation: '',
            solution_raisonsAutomatisationProcessus: '',
            solution_processusComptablePretPourAutomatisation: '',
            solution_coutMensuelPropose: '',
            solution_apprehensionsDonneesComptables: '',
            solution_apprehensionsDonneesComptablesObservation: '',
            solution_impressionParRapportDigitalisation: '',
            solution_impressionParRapportDigitalisationObservation: ''
        }
    },
    itemsSteps: [
        {
            value: 1,
            label: 'Identification',
            selected: false,
            completed: false,
        },
        {
            value: 2,
            label: 'Saisie comptable',
            selected: false,
            completed: false,
        },
        {
            value: 3,
            label: 'Paie',
            selected: false,
            completed: false,
        },
        {
            value: 4,
            label: 'Utilisation internet',
            selected: false,
            completed: false,
        },
        {
            value: 5,
            label: 'La solution',
            selected: false,
            completed: false,
        }
    ],
    currentStep: 1
}
  

let rootReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_STEP : 
        let currentStep = action.payload
        
        let items = state.itemsSteps
            if (currentStep !== 5) {
                items.map((item, index) => {
                    item.selected = false
                    if(currentStep > (index + 1)){
                        item.completed = true
                        item.selected = false
                        items[index] = item
                    }else{
                        item.completed = false
                        item.selected = false
                        items[index] = item
                    }
                    return item;
                })
                items[currentStep - 1].selected = true 
            }else if(currentStep === 5){
                items.map((item, index) => {
                    item.selected = false
                    if(currentStep > (index + 1)){
                        item.completed = true
                        item.selected = false
                        items[index] = item
                    }else{
                        item.completed = false
                        item.selected = false
                        items[index] = item
                    }
                    return item;
                })
                items[4].selected = true
            }

            state = {
                ...state,
                currentStep: currentStep,
                itemsSteps: items
            };

          break;
        case SET_STEPS : 
          let steps = action.payload
          state = {
            ...state,
            itemsSteps: steps
          };
          break;
        case SET_STEP_DATA : 
            let {step, data} = action.payload
            
          
           if(step === 1) {
            state = {
                ...state,
                form: {
                    ...state.form,
                    companyIdentification: data
                },
            };
           }
           if(step === 2) {
            state = {
                ...state,
                form: {
                    ...state.form,
                    accountingInformations: data
                }
            }; 
           }
           if(step === 3) {
            state = {
                ...state,
                form: {
                    ...state.form,
                    pay: data
                }
            }; 
           }
           if(step === 4) {
            state = {
                ...state,
                form: {
                    ...state.form,
                    useOfInternetAndTechnologies: data
                }
            }; 
           }
           if(step === 5) {
            state = {
                ...state,
                form: {
                    ...state.form,
                    newSoftwareOverview: data
                }
            }; 
           }
          break;
        default: break;
      }
      return state;
}

export default rootReducer