import React, { Component } from 'react'
import './form.css'
import { storeSurveyResult } from "../../services/apiCalls";
import StepOne from './steps/StepOne/StepOne';
import { FORM_ACTIONS } from '../../constants/actionsNames';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';
import StepFour from './steps/StepFour';
import StepFive from './steps/StepFive';
import { connect } from 'react-redux'
import { setCurrentStep, setStepData } from '../../store/actions';

class SurveyForm extends Component {
    constructor (props) {
        super(props)
        this.state = {
            form: {
                companyIdentification: {
                    denomination: '',
                    emailAddress: '',
                    legalForm: '',
                    isAccountingFirm: '',
                    activityArea: '',
                    companySeniority: '',
                    numberOfEmployees: '',
                    turnover: '',
                    monthlyAccountPayCost: '',
                    annualDsfCost: ''
                },
                accountingInformations: {
                    hasSelfAccountingService: '',
                    numberOfEmployees: '',
                    employees: '',
                    hasRelationshipWithAccountingFirm: '',
                    isUsingAccountingSoftware: '',
                    accountingSoftwaresUsed: '',
                    accountingInputFreqeuncy: '',
                    monthlyTreatedAccountingDocs: '',
                    minimumWastedTimePerMonthOnDocsTreating: '',
                    isDematerialExchange: ''
                },
                pay: {
                    companyHasPayrollService: '',
                    numberOfEmployees: '',
                    employees: '',
                    hasRelationshipWithAccountingFirm: '',
                    isUsingAccountingSoftware: '',
                    accountingSoftwaresUsed: '',
                    isDematerialExchangePay: ''
                },
                useOfInternetAndTechnologies: {
                    kindOfMediasUsed: '',
                    companyDigitalMatury: '',
                    cloudsToolsUsed: '',
                    internetAccessInsideCompany: '',
                    onlineProcedure: '',
                    bankingUsageSolution: '',
                },
                newSoftwareOverview: {
                    interestLevel: '',
                    reasonsOfTheProcessesAutomation: '',
                    accountingProcessesToAutomate: '',
                    willingPriceToPay: '',
                    apprehensions: '',
                }
            },
            currentStep: this.props.currentStep,
            loading: false,
        }

    }

   
    setCurrentStep = (step) => {
        this.props.setCurrentStep(step)
    }
  

    handleStepOne = (data, action) => {
        this.props.setStepData({step:1, data}, () => {
            if (action === FORM_ACTIONS.NEXT){
                this.setCurrentStep(2)
                window.scrollTo(0, 0)
            } 
        })
    }

    handleStepTwo = (data, action) => {

        this.props.setStepData({step:2, data}, () => {
            if (action === FORM_ACTIONS.NEXT) {
                this.setCurrentStep(3)
            } else {
                if (action === FORM_ACTIONS.PREVIOUS) {
                    this.setCurrentStep(1)
                }
            }
            window.scrollTo(0, 0)
        })

    }

    handleStepThree = (data, action) => {
        this.props.setStepData({step:3, data}, () => {
            if (action === FORM_ACTIONS.NEXT) {
                this.setCurrentStep(4)
            } else {
                if (action === FORM_ACTIONS.PREVIOUS) {
                    this.setCurrentStep(2)
                }
            }
            window.scrollTo(0, 0)
        })
       
    }

    handleStepFour = (data, action) => {
        this.props.setStepData({step:4, data}, () => {
            if (action === FORM_ACTIONS.NEXT) {
                this.setCurrentStep(5)
            } else {
                if (action === FORM_ACTIONS.PREVIOUS) {
                    this.setCurrentStep(3)
                }
            }
            window.scrollTo(0, 0)
        })
        
    }

    handleStepFive = (data, action) => {
        this.props.setStepData({step: 5, data}, () => {
            if (action === FORM_ACTIONS.SUBMIT) {
                this.submitSurveyResult(data);
               // this.handleEnd() 
            } else {
                if (action === FORM_ACTIONS.PREVIOUS) {
                    this.setCurrentStep(4)
                }
            }
            window.scrollTo(0, 0)
        })
        
    }

   

    submitSurveyResult = async (step_five) => {
        // todo: call the surveyJs endpoint to save the survey result and redirect to /thanks page
        this.setState({
            loading: true
        });
        const data = { 
            ...this.props.rootReducer.form.companyIdentification, 
            ...this.props.rootReducer.form.accountingInformations, 
            ...this.props.rootReducer.form.pay, 
            ...this.props.rootReducer.form.useOfInternetAndTechnologies, 
            ...this.props.rootReducer.form.newSoftwareOverview,
            ...step_five
        }
        const result = await storeSurveyResult(data);
        if(result.status !== 200 ) console.error("ERROR");
        this.setState({
            loading: false
        }, () => this.handleEnd());
    }

    handleEnd = () => {
        this.props.history.push('/thanks')
    }

    gotoStep = (step) => {
        // this.setCurrentStep(step)
        this.props.setCurrentStep(step)
    }

    loadSteps = () => {
        let { currentStep, form } = this.props.rootReducer
        let { history } = this.props
        switch (currentStep) {
            case 1 : return <StepOne data={form.companyIdentification} handleStepOne={this.handleStepOne}/>
            case 2 : return <StepTwo data={form.accountingInformations} handleStepTwo={this.handleStepTwo}/>
            case 3 : return <StepThree data={form.pay} handleStepThree={this.handleStepThree}/>
            case 4 : return <StepFour data={form.useOfInternetAndTechnologies} handleStepFour={this.handleStepFour}/>
            case 5 : return <StepFive data={form.newSoftwareOverview} handleStepFive={this.handleStepFive}/>
            default: history.push('/'); break;
        }
    }

    renderLoader = () => {
        if(this.state.loading){
            return(
                <div className="loader-box fade-in-bottom animation-fill-mode-both duration-half">
                    <div className="loader-spinner"> <span className="l-spinner spinner infinite"></span> </div>
                    <div className="loader-statement">Traitement en cours...</div>
                </div>
            )
        }
    }

    render(){
        return (
            <div className="pb-50">
                { this.loadSteps() }
                { this.renderLoader() }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rootReducer: state.rootReducer
    }
}

export default connect(mapStateToProps, {setCurrentStep, setStepData})(SurveyForm)