import React, {Component} from 'react';
import './App.css';
import './App-animations.css';
import { Switch, Route } from 'react-router-dom'
import SurveyForm from './pages/form';
import Welcome from './pages/welcome';
import HeaderStep from './components/HeaderStep'
import Footer from './components/Footer';

class App extends Component {

  constructor(props){
    super(props)

    this.state = {
      currentStep: 1
    }
  }

  setStep = (step) => {
    this.setState({
      currentStep: step
    },() => {
      this.getCurrentStep(step)
    })
  }

  getCurrentStep = (step) => {
    const s = step || this.state.currentStep
    return s
  }

  render () {
    const {currentStep} = this.state
    return (
      <div className="App">
          <Switch>
            <Route exact path='/' component={Welcome}/>
              <Route path='/welcome' 
                render={({ history }) => {
                  return <Welcome history={history} />;
                }}/>
              <Route path='/thanks' 
                render={({ history }) => {
                  return <Welcome history={history} position={'end'} />;
                }}/>
            <Route path='/form' 
              render={({ history }) => {
                return (
                  <div>
                    <HeaderStep loadStep={this.setStep} />
                      <SurveyForm history={history} currentStep={currentStep} targetStep={this.setStep} />
                    <Footer/>
                  </div>
                  );
              }}/>
          </Switch>
      </div>
    );
  }
}

export default App;
