import React, { Component } from 'react'
import './StepThree.css'
import { FORM_ACTIONS } from '../../../../constants/actionsNames'
import {ItemsPaie} from '../../../../constants/imgItems'
import { Container, Row, Col } from 'react-bootstrap'
import HeadTitle from '../../../../components/HeadTitle'
import ImputRadioBox from '../../../../components/ImputRadioBox'
import InputButton from '../../../../components/InputButton'
import leftArrow from '../../../../assets/svg/left-arrow.svg'
import rightArrow from '../../../../assets/svg/right-arrow.svg'
import InputBox from '../../../../components/InputBox'
import SelectaImage from '../../../../components/SelectaImage'
import SurveyHelper from '../../../../Helpers/SurveyHelper'

class StepThree extends Component {
    constructor (props) {
        super(props)
        this.state = {
            pay: {
                ...this.props.data
            }
        }
    }

    handleNext = (e) => {
        if (this.props.handleStepThree)
            this.props.handleStepThree(this.state.pay, FORM_ACTIONS.NEXT)
    }

    handlePrev = (e) => {
        if (this.props.handleStepThree)
            this.props.handleStepThree(this.state.pay, FORM_ACTIONS.PREVIOUS)
    }
    parsing = (value, base) => {
        let parsed = parseInt(value, base);
        if (isNaN(parsed)) { return 0 }
        return parsed
    }

    handleHasPayrollService = (value) => {
        let SurveyHelp = new SurveyHelper()

        SurveyHelp.hideOrShowContent('.row-2',value,'oui')
        SurveyHelp.hideOrShowContent('.row-3',value,'oui')
        SurveyHelp.hideOrShowContent('.row-4',value,'oui')
        SurveyHelp.hideOrShowContent('.row-5',value,'non')

        this.setState({
            pay: {
            ...this.state.pay,
            paie_gestionPaieInterne: value
          }
        })
    }

    handleHasPayrollService2 = (value) => {
        this.setState({
            pay: {
            ...this.state.pay,
            paie_logicielDePaieUtilises: value.toString(),
          }
        })
    }

    handleEmployeeScale = (value) => {
        this.setState({
            pay: {
            ...this.state.pay,
            paie_nombreEmployes: value,
          }
        })
    }

    handleHasRelationship = (value) => {
        this.setState({
            pay: {
            ...this.state.pay,
            paie_relationAvecCabinetComptable: value
          }
        })
    }

    handleIsUsingAccountingSoftware = (value) => {
        this.setState({
            pay: {
             ...this.state.pay,
             paie_usageLogicielComptable: value
           }
        })
    }

    handleOne = (value) => {
    }

    handleIsDematerialExchange = (value) => {
        this.setState({
            pay: {
             ...this.state.pay,
             paie_echangesCabinetDematerialises: value
           }
        })
    }

    componentDidMount(){
        const {companyHasPayrollService} = this.state.pay

        if(companyHasPayrollService){
            this.handleHasPayrollService(companyHasPayrollService) 
        }
    }

    render () {
        const {paie_gestionPaieInterne,paie_relationAvecCabinetComptable,paie_usageLogicielComptable,paie_nombreEmployes,paie_echangesCabinetDematerialises,paie_logicielDePaieUtilises} = this.state.pay
        let itemsImg = ItemsPaie
        const subThree = <SelectaImage statement={<span>Lesquels ? <small className="bluely"><i>(plusieurs choix sont possible)</i></small> </span>} items={itemsImg} preventValue={paie_logicielDePaieUtilises.split(',')} getResult={this.handleHasPayrollService2} multiple others lighterBg className={'p-start fade-in-top-light animation-fill-mode-both'}  />
        const q3 = <span>Utilisation de logiciel de paie ? </span>
        const lastq = <span>Est-ce que les échanges avec votre cabinet sont dématérialisés ? </span>

        return(
            <Container className="step-two">
                <Row>
                    <Col>
                        <HeadTitle title={'GESTION DE LA PAIE'} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ImputRadioBox statement={'Gestion de la paie en interne ?'} getResult={this.handleHasPayrollService}  preventValue={paie_gestionPaieInterne} />
                    </Col>
                </Row>
                <Row className='row-2 none'>
                    <Col>
                        <InputBox statement={'Combien d’employés à ce service ?'} type={'number'} getResult={this.handleEmployeeScale} value={paie_nombreEmployes} />
                    </Col>
                </Row>
                <Row className='row-3 none'>
                    <Col>
                        <ImputRadioBox statement={'Relation avec un cabinet comptable pour la révision ?'} getResult={this.handleHasRelationship} preventValue={paie_relationAvecCabinetComptable}  />
                    </Col>
                </Row>
                <Row className='row-4 none'>
                    <Col>
                        <ImputRadioBox statement={q3} other as={subThree} getResult={this.handleIsUsingAccountingSoftware} preventValue={paie_usageLogicielComptable}  />
                    </Col>
                </Row>
                <Row className="row-5 none">
                    <Col>
                    <ImputRadioBox statement={lastq} getResult={this.handleIsDematerialExchange}  preventValue={paie_echangesCabinetDematerialises} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="actions-wrapper">
                            <InputButton id={'prevtwo'} name={'prevtwo'} value={'PRECEDENT'} leftIcon={leftArrow} getResult={this.handleOne} onClick={this.handlePrev} />
                            <InputButton id={'nexttwo'} name={'nexttwo'} value={'SUIVANT'} rightIcon={rightArrow} getResult={this.handleOne} onClick={this.handleNext} />
                        </div>
                    </Col>
                </Row>
                
            </Container>
        )
    }
}

export default StepThree