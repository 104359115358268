import React from 'react'
import {Container,Row,Col} from 'react-bootstrap'

import './end.css'

const End = (props) => {

    return(
        <Container className="content">
            <Row>
                <Col>
                    <div className="end">
                        <div className="content-message">
                            <span className="ico-smiley p-start fade-in-left-light animation-fill-mode-both duration-1 delay-less-3"></span>
                            <div className="p-start fade-in-right-light animation-fill-mode-both duration-1 delay-less-3">
                                <p className="font-size-17"><span className="big-bold-white block">Nous vous remercions</span> pour votre contribution &amp; espérons vous revoir très bientôt</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            
        </Container>
    )
}

export default End