import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import './Header.css'

const Header = () => {

    return(
        <header className="header">
            <Container>
                <Row>
                    <Col>
                        <div className="logo">
                            <h2 className="main-title p-start fade-in-top-light animation-fill-mode-both duration-1">DIGITAL ACCOUNTING</h2>
                        </div>
                        
                    </Col>
                </Row>
            </Container>
        
        </header>
    )
}

export default Header