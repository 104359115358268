class SurveyHelper {

    hideOrShowContent = (selector, testValue, trueValue) => {
        let selectorObj = document.querySelector(selector)

        if(testValue === trueValue){
            if(selectorObj.classList.contains('none')){
                selectorObj.classList.remove('none') 
            }  
        }else{
            if(!selectorObj.classList.contains('none')){
                selectorObj.classList.add('none') 
            }
        }
        
        return selectorObj
    }

    parsing = (value, base) => {
        let parsed = parseInt(value, base);
        if (isNaN(parsed)) { return 0 }
        return parsed
    }

    staticHeader = () => {
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY > 100;
            let selectorObj = document.querySelector('.HeaderStep')
            let app = document.querySelector('.App')
            if(selectorObj !== null){
                if (isTop) {
                    if(!selectorObj.classList.contains('offset')){
                        app.classList.add('pt-100')
                        selectorObj.classList.add('offset')
                    }
                }else{
                    if(selectorObj.classList.contains('offset')){
                        app.classList.remove('pt-100')
                        selectorObj.classList.remove('offset')
                    }
                        
                }
            }else{
                if(app.classList.contains('pt-100')){
                    app.classList.remove('pt-100')
                }
            }
            
            
        });
    }

}

export default SurveyHelper