import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import './Start.css'

import ChronomettreComponent from '../../../components/Chronomettre'
import InputButton from '../../../components/InputButton'

import arrowdot from '../../../assets/svg/arrow-dots-right.svg'
// import digitalComputer from '../../../assets/svg/digitalComputer.svg'


const Start = (props) => {

    const handleClick = () => {
        props.history.push('/form')
        window.scrollTo(0, 0)
    }
    
    return(
        
        <Container className="content">
            <Row>
                <Col>
                    <h4 className="titled p-start fade-in-top-light animation-fill-mode-both duration-1 delay-less-2">
                        <span className="bold">Bonjour</span> &agrave; vous, Entrepreneur, Comptable.
                    </h4>

                    <div className="w-pmessage mt-50 p-start fade-in-top-light animation-fill-mode-both duration-1 delay-less-3">
                        <p>Et si vous n’aviez plus besoin de passer des heures à rapprocher des écritures bancaires ?</p>
                        <p>Si la saisie des pièces comptables, corvée incontournable, n’était plus nécessaire ?</p>
                        <p>Si vous pouviez d’un clic générer des fiches de paie conformes à la règlementation ?</p>
                        <p>Et si, en fin d’année, un système se chargeait de générer votre DSF ?</p>

                        <p className="mt-15 ">Nous aimerions avoir votre avis sur la <span className="bold">digitalisation</span>  des <span className="bold">processus comptables</span>. Nous vous 
                        remercions d’avance pour le temps que vous accorderez à remplir cette enquête. </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex mb-50 mt-10 p-start fade-in-top-light animation-fill-mode-both duration-1 delay-less-4">
                    <ChronomettreComponent />
                    <div className="ml-20 w-550 light-white-color" >
                        <h3 className="timer-msg mt-10"><span className="time-defined">3 minutes</span></h3>
                        <p> C’est le temps qu’il vous faudra pour remplir ce formulaire, depuis votre <b>ordinateur</b> ou <b>smartphone</b> </p>
                    </div>
                    
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center p-start fade-in-top-light animation-fill-mode-both duration-1 delay-half">
                    <InputButton name={'begin'} value={'Commencer maintenant'} rightIcon={arrowdot} variant={'primary'} onClick={handleClick} className="ico-on-hover" />
                </Col>
            </Row>
        </Container>
    )
}

export default Start