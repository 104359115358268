import React, {Component} from 'react'
import './SelectaImage.css'
import SelectaItemImg from './SelectaItem'

// eslint-disable-next-line no-lone-blocks
{/* 
    <Selecta
    statement={statement}  ---> title or question
    items={items}  ---> Object children
    multiple  ---> multiple choice, not required, default: false
    others  ---> auther choice, not required, default: false
    getResult={this.handleSelect}
    /> 
*/}

class SelectaImage extends Component {
    constructor(props){
        super(props)

        this.state = {
            statement: this.props.statement,
            items: this.props.items,
            value: [],
            multiple: this.props.multiple,
            others: this.props.others,
            boxOther: false,
            inputOtherValue: '',
            lighterBg: this.props.lighterBg,
            className: this.props.className,
            preventValue: this.props.preventValue
        }
       
    }

    unSelectAll = items => {
        items.map((item) => (
            item.isSelected = false
        ))
        return items
    }

    setitem = (obj,index) => {
        let {items, multiple} = this.state
        let res = []
        if(!multiple){
            items = this.unSelectAll(items)
            obj.isSelected ? res = obj.value : res = ''
            items[index] = obj
            this.setState({
                items: items,
                value: res,
                boxOther: false,
                inputOtherValue: ''
            },() => {
                const {getResult} = this.props
                getResult(this.state.value)
            })
        }else{
            let {items} = this.state
           
            items[index] = obj
            this.setState({
                items: items,
                value: res,
                boxOther: false,
                inputOtherValue: ''
            },() => {
                let {value,items} = this.state
                items.map((item) => {
                    if(item.isSelected){
                        value.push(item.value);
                        return item;
                    }
                    return item;
                })
                const {getResult} = this.props
                getResult(value)
            }) 

            
        }
        
        
        
    }
  
    addOther = () => {
    
           let {items} = this.state
            const {multiple} = this.state
            if(!multiple){ items = this.unSelectAll(items)}
            //items = this.unSelectAll(items)
            this.setState({
                items,
                boxOther: true
            })
    }

    handleChoice = ({target: {value}}) => {
        this.setState({
            value,
            inputOtherValue: value,
        }, () => { 
            const {getResult} = this.props
            getResult(value)
        })
    }

    
    findInArray = (table, itemResearched) => {
        
        var res = table.findIndex(function(element) {
            return element.value === itemResearched;
        });

        return res
    }
    

    setPreventValue = () => {
        const {preventValue,multiple} = this.state
        
        if(preventValue){
            let {items} = this.state
            this.unSelectAll(items)
            if(!multiple){
                const index = this.findInArray(items, preventValue)
                items[index].isSelected = true
                this.setState({
                    items,
                    value: preventValue
                },() => { })
                
            }else{
                if(typeof preventValue === "object"){
                    preventValue.map((item, index) => {
                        const i = this.findInArray(items, item);
                        if(i >= 0){
                            items[i].isSelected = true;
                        }
                        return item;
                    })
                    this.setState({
                        items,
                        value: preventValue
                    },() => { })
                }
                
            }
        }
        
    }

    componentWillMount(){
        this.setPreventValue()
    }

    render(){
        const {statement,items,others,boxOther,inputOtherValue,lighterBg,className} = this.state
        return(
            <div className={lighterBg ? "selectaImg lighter-bg "+ className : "selectaImg "+ className }>
                <div className="statement">{statement}</div>
                <div>
                {
                    items.map((item, index) => (
                        <SelectaItemImg 
                            key={index}
                            index={index} 
                            obj={item}
                            setitem={this.setitem}
                        />
                        
                    ))
                }
                
                {
                    (others === true) ? <button type='button' className='btn-iselecta' onClick={this.addOther}>autres</button> : ''

                }
                </div>
                {
                    (boxOther === true) ? <div className='box-other p-start fade-in-top-light animation-fill-mode-both'><div><input type="text" className='s-form' onChange={this.handleChoice} value={inputOtherValue} placeholder="Saisir un autre choix" /></div></div> : ''    
                }
            </div>
        )
    }
}

export default SelectaImage