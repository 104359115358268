import React, {Component} from 'react'
import {Row,Col} from 'react-bootstrap'

import './InputRateBox.css'
import Smiley from '../../constants/SmileyRate'
import Verygood from './verygood'
import Good from './good'
import Bad from './bad'

class InputRateBox extends Component{

    constructor(props){
        super(props)

        this.state = {
            statement: this.props.statement,
            items: this.props.items || [
                {
                    index: 0,
                    title: 'Très intéressé',
                    value: 'Très intéressé',
                    src: Smiley.verygood,
                    selected: false
                },
                {
                    index: 1,
                    title: 'Plutôt intéressé',
                    value: 'Plutôt intéressé',
                    src: Smiley.good,
                    selected: false
                },
                {
                    index: 2,
                    title: 'Pas intéressé',
                    value: 'Pas intéressé',
                    src: Smiley.bad,
                    selected: false
                },
            ],
            value: { value: '', observation: '' },
            preventValue: this.props.preventValue

        }
    }

    unSelectAll = items => {
        items.map((item) => (
            item.selected = false
        ))
        return items
    }

    handleClick = (e) => {
        const valueStr = e.currentTarget.value
        const part = valueStr.split(',')
        const value = part[0]
        const index = part[1]
        let {items} = this.state

        items = this.unSelectAll(items)
        items[index].selected = true

        if(this.props.withObservation){
            this.setState({
                items,
                value: { ...this.state.value, value: value },
            },() => {
                let {getResult} = this.props;
                getResult(this.state.value)
            })
        }else{
            this.setState({
                items,
                value,
            },() => {
                let {getResult} = this.props;
                getResult(this.state.value)
            });
        }
        
        
    }

    handleObservation = (e) => {
        const {value} = e.target;
        this.setState({
            value: { ...this.state.value, value: this.state.value.value || '', observation: value },
        },() => {
            let {getResult} = this.props;
            getResult(this.state.value)
        })
    }

    setPreventValue = () => {
        const {preventValue} = this.state;
        const {withObservation} = this.props;
        if(preventValue && preventValue !== ''){
            let {items} = this.state;
            const index = items.findIndex(function(elem){
                if(withObservation){
                    return elem.value === preventValue.value
                }
                return  elem.value === preventValue   
            });
            items = this.unSelectAll(items);
            if(index >= 0){
                items[index].selected = true;
            }
            
            this.setState({
                items,
                value: preventValue,
            })
        }else{
            console.error('valeur nulle! ', preventValue)
        }
        
    }

    UNSAFE_componentWillMount(){
        if(this.props.items){
            let items = this.props.items;
            items = [ ...items, {...items[0], src: Smiley.verygood }, {...items[1], src: Smiley.good }, {...items[2], src: Smiley.bad } ];
            this.setState({ ...this.state.items, items });
        }
    }

    componentWillMount(){
        
        this.setPreventValue()
        //this.forceUpdate()
    }

    renderBoservation = () => {
        if(this.props.withObservation){
            const placeholder = this.props.observationPlaceholder ? this.props.observationPlaceholder : "Avez-vous des observations?";
            return(
                <Col md={12} sm={12} className="mb-20">
                    <textarea row={10} className="form form-control" placeholder={placeholder} onChange={this.handleObservation} value={this.state.value.observation}></textarea>
                </Col>
            )
        }
    }

    renderWithDescription = () => {
        if(this.props.withDescription){
            return (
                <div className="description">{ this.props.withDescription }</div>
            );
        }
    }

    render(){
        const {statement, items} = this.state
        return(
            <Row className="ratebox">
                <Col md={12} sm={12}>
                    <div className="statement">{statement}</div>
                    { this.renderWithDescription() }
                    <ul className="listrate">
                        
                        <li>
                            <label className={(items[0].selected) ? "item-rate r-selected" : "item-rate" }>
                                <button type="button" value={[items[0].value,items[0].index]} onClick={this.handleClick}><Verygood selected={(items[0].selected) ? true : false } /></button>
                                <span>{items[0].title}</span>
                            </label>
                        </li>  
                        <li>
                            <label className={(items[1].selected) ? "item-rate r-selected" : "item-rate" }>
                                <button type="button" value={[items[1].value,items[1].index]} onClick={this.handleClick}><Good selected={(items[1].selected) ? true : false } /></button>
                                <span>{items[1].title}</span>
                            </label>
                        </li> 
                        <li>
                            <label  className={(items[2].selected) ? "item-rate r-selected" : "item-rate" }>
                                <button type="button" value={[items[2].value,items[2].index]} onClick={this.handleClick}><Bad selected={(items[2].selected) ? true : false } /></button>
                                <span>{items[2].title}</span>
                            </label>
                        </li>
                    </ul>
                </Col>
            { this.renderBoservation() }
            </Row>
        )
    }

}

export default InputRateBox