import axios from 'axios'
import uuidv1 from 'uuid'

export const storeSurveyResult = async (surveyResult) => {
    const id = uuidv1()
    let data = {
        PostId: process.env.REACT_APP_SURVEY_POST_ID,
        SurveyResult: JSON.stringify(surveyResult),
        ClientId: id,
        IsPartialCompleted: true
    }
    const instance = axios.create({
        baseURL: process.env.REACT_APP_SURVEY_PUBLIC_URI,
        headers: {'Content-Type': 'application/json'},
        method: 'post',
        data
    })
    return await instance('/Survey/post');

}

export const getSurveyResults = async () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_SURVEY_PRIVATE_URI,
        method: 'get',
        params: {
            accessKey: process.env.REACT_APP_SURVEY_ACCESS_KEY
        }
    })
    return await instance('/MySurveys/getSurveyResults/' + process.env.REACT_APP_SURVEY_ID)
}