import React, {Component} from 'react'

import './Selecta.css'
import SelectaItem from './SelectaItem'

// eslint-disable-next-line no-lone-blocks
{/* 
    <Selecta
    statement={statement}  ---> title or question
    items={items}  ---> Object children
    multiple  ---> multiple choice, not required, default: false
    others  ---> auther choice, not required, default: false
    getResult={this.handleSelect}
    /> 
*/}

class Selecta extends Component {
    constructor(props){
        super(props)

        this.state = {
            statement: this.props.statement,
            items: this.props.items,
            value: [],
            multiple: this.props.multiple,
            others: this.props.others,
            boxOther: false,
            inputOtherValue: '',
            lighterBg: this.props.lighterBg,
            preventValue: this.props.preventValue,
            observation: '',
        }
       
    }

    unSelectAll = items => {
        items.map((item) => (
            item.isSelected = false
        ))
        return items
    }

    withObservationTest = (val) => {
        if(this.props.withObservation){
            return { value: val, observation: this.state.observation }
        }
        return val;
    }

    setitem = (obj,index) => {
        let {items, multiple} = this.state
        let res = []
        if(!multiple){
            items = this.unSelectAll(items)
            obj.isSelected ? res = this.withObservationTest(obj.value) : res = this.withObservationTest('');
            items[index] = obj
            this.setState({
                items: items,
                value: res,
                boxOther: false,
                inputOtherValue: ''
            },() => {
                const {getResult} = this.props
                getResult(this.state.value)
            })
        }else{
            let {items} = this.state
           
            items[index] = obj
            this.setState({
                items: items,
                value: res,
                boxOther: false,
                inputOtherValue: ''
            },() => {
                let {value,items} = this.state
                items.map((item) => {
                    if(item.isSelected){
                        value.push(item.value)
                        return item;
                    }
                    return item;
                })
                const {getResult} = this.props
                getResult(value)
            }) 

            
        }
        
        
        
    }

    addOther = () => {
        let {items} = this.state
        items = this.unSelectAll(items)
        this.setState({
            items,
            boxOther: true
        })
    }

    handleChoice = ({target: {value}}) => {
        this.setState({
            value,
            inputOtherValue: value,
        }, () => { 
            const {getResult} = this.props
            getResult(value)
        })
    }

    findInArray = (table, itemResearched) => {
        
        var res = table.findIndex(function(element) {
            return element.value === itemResearched;
        });

        return res
    }
    

    setPreventValue = () => {
        const {preventValue,multiple} = this.state
        if(preventValue){
            let {items} = this.state
            this.unSelectAll(items)
            if(!multiple){
                const pv = this.props.withObservation ? preventValue.value : preventValue
                const index = this.findInArray(items, pv)
                if(index >= 0){
                    items[index].isSelected = true
                }
                this.setState({
                    items,
                    value: preventValue
                })
                
            }else{
                preventValue.map((item, index) => {
                    const pv = this.props.withObservation ? item.value : item
                    const i = this.findInArray(items, pv)
                    if(i >= 0){
                        items[i].isSelected = true;
                        return item;
                    }
                    return item;
                })
                this.setState({
                    items,
                    value: preventValue
                },() => { })
            }
        }else{
            
        }
        
    }

    UNSAFE_componentWillMount(){
        this.setPreventValue()
    }

    renderObservation = () => {
        if(this.props.withObservation){
            const placeholder = this.props.observationPlaceholder ? this.props.observationPlaceholder : "Avez-vous des observations?";
            return(
                <div className="mb-20">
                    <textarea row={10} className="form form-control" placeholder={placeholder} onChange={this.handleObservation} value={this.state.value.observation}></textarea>
                </div>
            )
        }
    }

    handleObservation = (e) => {
        const {value} = e.target;
        this.setState({
            value: { ...this.state.value, value: this.state.value.value || '', observation: value },
            observation: value,
        },() => {
            let {getResult} = this.props;
            getResult(this.state.value)
        })
    }

    render(){
        const {statement,items,others,boxOther,inputOtherValue,lighterBg,preventValue} = this.state
        return(
            <div className={lighterBg ? "selecta lighter-bg" : "selecta"}>
                <div className="statement">{statement}</div>
                {
                    items.map((item, index) => (
                        <SelectaItem 
                            key={index}
                            index={index} 
                            obj={item}
                            setitem={this.setitem}
                            setDefaultSelected={preventValue}
                        />
                        
                    ))
                }
                {
                    (others === true) ? <button type='button' className='btn-selecta' onClick={this.addOther}>autres</button> : ''

                }
                {
                    (boxOther === true) ? <div className='box-other'><div><input type="text" className='s-form' onChange={this.handleChoice} value={inputOtherValue} placeholder="Saisir un autre choix" /></div></div> : ''    
                }
                { this.renderObservation() }
            </div>
        )
    }
}

export default Selecta