import React, {Component} from 'react'
import PropTypes from 'prop-types'

import './ImputRadioBox.css'

class ImputRadioBox extends Component{

    constructor(props){
        super(props)

        this.state = {
            statement: this.props.statement,
            value: '',
            other: this.props.other,
            otherValue: '',
            yesSelected: false,
            noSelected: false,
            preventValue: this.props.preventValue
        }
    }

    handleSelect = ({target: {value}}) => {
        let {getResult} = this.props
        switch (value){
            case 'oui': {
                let val = this.props.withObservation ? {value: value, observation: this.state.value.observation || ''} : value;
                this.setState({
                    value: val,
                    yesSelected: true,
                    noSelected: false
                },()=>{
                    getResult(this.state.value)
                })
                break
            }
            case 'non': {
                let val = this.props.withObservation ? {value: value, observation: this.state.value.observation || ''} : value;
                this.setState({
                    value: val,
                    yesSelected: false,
                    noSelected: true
                },()=>{
                    getResult(this.state.value)
                })
                break
            }
            default: {

                break
            }
        }
    }

    setPreventValue = () => {
        const {preventValue} = this.state
        if(preventValue && preventValue !== ''){
            let {getResult} = this.props
            const pv = this.props.withObservation ? preventValue.value : preventValue;
            if(pv === 'non'){
                this.setState({
                    value: preventValue,
                    yesSelected: false,
                    noSelected: true
                },()=>{
                    getResult(this.state.value)
                })
            }else if(pv === 'oui'){
                this.setState({
                    value: preventValue,
                    yesSelected: true,
                    noSelected: false
                },()=>{
                    getResult(this.state.value)
                })
            }else if(pv !== 'oui' && preventValue !== 'non'){
                this.setState({
                    value: preventValue,
                    yesSelected: false,
                    noSelected: false,
                    other: true,
                },() => {
                    getResult(this.state.value)
                })
            }
        }else{
            console.warn('valeur nulle! ', preventValue)
        }
        
    }

    renderObservation = () => {
        if(this.props.withObservation && this.props.observationConstraint && this.state.value.value === this.props.observationConstraint){
            const placeholder = this.props.observationPlaceholder ? this.props.observationPlaceholder : "Avez-vous des observations?";
            return(
                <div className="mb-20 p-start fade-in-top-light animation-fill-mode-both">
                    <textarea row={10} className="form form-control" placeholder={placeholder} onChange={this.handleObservation} value={this.state.value.observation}></textarea>
                </div>
            )
        }
    }

    handleObservation = (e) => {
        const {value} = e.target;
        this.setState({
            value: { ...this.state.value, value: this.state.value.value || '', observation: value },
        },() => {
            let {getResult} = this.props;
            getResult(this.state.value)
        })
    }

    componentWillMount(){
        
        this.setPreventValue()
        //this.forceUpdate()
    }

    render(){
        const {statement,yesSelected,noSelected,other} = this.state 
        var input = this.props.as
        return(
            <div className="inputradio">
                <div className="statement">{statement}</div>
                <div className="radio-content">
                    <label htmlFor="radio-item" className="radio-item">
                        <button type="button" value={'oui'} className={yesSelected ? 'selected' : ''} onClick={this.handleSelect}>{''}</button>
                        <span>Oui</span>
                        <button type="button" value={'non'} className={noSelected ? 'selected' : ''} onClick={this.handleSelect}>{''}</button>
                        <span>Non</span>
                    </label>
                    <div className="other">
                        {
                            (yesSelected === true && other === true) ? input : ''
                        }
                    </div>
                </div>
                { this.renderObservation() }
            </div>
        )
    }

}
ImputRadioBox.propTypes = {
    getResult: PropTypes.func.isRequired,
    //preventValue: PropTypes.string.isRequired
}
export default ImputRadioBox