import React from 'react'
import './bad.css'

const Bad = (props) => {
    const selected = props.selected
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" className={(selected === true) ? "r-selected" : ""}>
            <g id="smiley_middle_good" data-name="smiley middle good" transform="translate(-387 -870)">
            <circle id="bad" data-name="Ellipse 9" cx="13" cy="13" r="13" transform="translate(387 870)" fill="#B2B2B2"/>
            <g id="Groupe_46" data-name="Groupe 46" transform="translate(1 1)">
                <ellipse id="Ellipse_10" data-name="Ellipse 10" cx="1" cy="3" rx="1" ry="3" transform="translate(392 876)" fill="#fff"/>
                <ellipse id="Ellipse_11" data-name="Ellipse 11" cx="1" cy="3" rx="1" ry="3" transform="translate(405 876)" fill="#fff"/>
            </g>
            <path id="Tracé_21" data-name="Tracé 21" d="M393,886.567c-.025.365,7.7,6.525,14.228,0C407.359,886.5,401.272,889.936,393,886.567Z" transform="translate(800.23 1777.469) rotate(180)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default Bad