import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import './Footer.css'

import Logo2 from '../../assets/svg/logo2.svg'
import globe from '../../assets/svg/globe.svg'

const Footer = () => {

    return(
        <footer className="footer">
            <Container>
                <Row>
                    <Col>
                        <div className="website p-start fade-in-right-light animation-fill-mode-both duration-1 delay-less-6">
                            <img src={globe} alt="website" />
                            <a href="https://www.mboadigital.tech" rel="noopener noreferrer" target="_blank">www.mboadigital.tech</a>
                        </div>
                        <div className="logo2 p-start fade-in-left-light animation-fill-mode-both duration-1 delay-less-6">
                        <a href="https://www.mboadigital.tech" target="_blank" rel="noopener noreferrer" ><img src={Logo2} alt="Logo2"/></a>
                        </div>
                    </Col>
                </Row>
            </Container>
            
        </footer>
    )
}

export default Footer