import React, {Component} from 'react'
import { connect } from 'react-redux'
import {Container, Row, Col} from 'react-bootstrap'
import ItemsSteps from '../../constants/ItemsSteps'
import SurveyHelper from '../../Helpers/SurveyHelper'
import { setCurrentStep, setSteps } from '../../store/actions'
import './HeaderStep.css'

class HeaderStep extends Component {

    constructor(props){
        super(props)
        this.state = {
            currentStep: this.props.rootReducer.currentStep,
            steps: ItemsSteps
        }

        this.setCurrentStep = this.setCurrentStep.bind(this)
    }

    componentDidMount(){
        this.setCurrentStep()
        let Hscroll = new SurveyHelper()
        Hscroll.staticHeader()
    }
    

    setCurrentStep = () => {
        let {currentStep, itemsSteps} = this.props.rootReducer
        
        let items = [...itemsSteps]
        let i = currentStep - 1
        itemsSteps.map((item, index) => {
            if(currentStep > (index + 1)){
                item.completed = true;
                item.selected = false;
                items[index] = item;
                return item;
            }else{
                item.completed = false;
                item.selected = false;
                items[index] = item;
                return item;
            }
        })
        items[i].selected = true
        this.props.setSteps(items)
    }

    handleSetStep = (e) => {
        e.preventDefault()
        let {value} = e.target

        this.props.setCurrentStep(value*1)
        let {itemsSteps} = this.props.rootReducer 
        
        let items = [...itemsSteps]
        itemsSteps.map((item, index) => {
            if(value > (index + 1)){
                item.completed = true;
                item.selected = false;
                items[index] = item;
                return item;
            }else{
                item.completed = false;
                item.selected = false;
                items[index] = item;
                return item;
            }
        })
        items[value-1].selected = true
        this.props.setSteps(items)
        
    }

    componentWillUpdate () {
        // this.handleSetStep({target:{value:this.props.rootReducer.currentStep}})
    }


    render(){
        let { itemsSteps } = this.props.rootReducer
        
        return(
            <header className="HeaderStep slide-in-top duration-half animation-fill-mode-both">
                <Container>
                    <Row>
                        <Col md={12} sm={12}>
                            <h2 className="main-title p-start fade-in-top-light animation-fill-mode-both duration-1">DIGITAL ACCOUNTING</h2>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className="slide-in-top deley-1 duration-1 animation-fill-mode-both">
                        <Col>
                            <div className="step-box">

                                {
                                    itemsSteps.map((item, index) => (
                                        <span className={"step-item " + ((item.selected) ? " selected" : "") + ((item.completed) ? " complete" : "")} key={index}>
                                        <button className="btn-step" value={item.value} onClick={this.handleSetStep} > {item.value} </button>
                                        <span className="step-label">{item.label}</span>
                                    </span>
                                    ))
                                }
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            
            </header>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rootReducer: state.rootReducer
    }
}

export default connect(mapStateToProps, {setCurrentStep, setSteps})(HeaderStep)