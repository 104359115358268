import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setCurrentStep, setStepData } from '../../../../store/actions';
import {Container,Row,Col} from 'react-bootstrap'
import './StepFive.css'
import { FORM_ACTIONS } from '../../../../constants/actionsNames';
import HeadTitle from '../../../../components/HeadTitle'
import Selecta from '../../../../components/Selecta'
import InputRateBox from '../../../../components/InputRateBox'
import ImputRadioBox from '../../../../components/ImputRadioBox'
import InputButton from '../../../../components/InputButton'

import leftArrow from '../../../../assets/svg/left-arrow.svg'
import save from '../../../../assets/svg/save.svg'
import ItemsStepFive from '../../../../constants/ItemsStepFive'

class StepFive extends Component {
    constructor (props) {
        super(props)
        this.state = {
            newSoftwareOverview: {
                ...this.props.data
            }
        }
    }

    handleSubmit = () => {
        const {newSoftwareOverview} = this.state;
        console.log("State step 5: ", newSoftwareOverview);

        this.props.setStepData({step: 5, newSoftwareOverview}, () => {
            if (this.props.handleStepFive)
                this.props.handleStepFive(newSoftwareOverview, FORM_ACTIONS.SUBMIT);
                console.log("Store step 5*: ", this.props.rootReducer.form.newSoftwareOverview);
            window.scrollTo(0, 0)
        })
    }


    handlePrev = () => {
        if (this.props.handleStepFive)
            this.props.handleStepFive(this.state.newSoftwareOverview, FORM_ACTIONS.PREVIOUS)
    }

    handleEnd = () => {
        this.props.history.push('/thanks')
    }

    handleInterestLevel = (value) => {
        
        this.setState({
            newSoftwareOverview: {
            ...this.state.newSoftwareOverview,
            solution_niveauInteretAppCloud: value
          }
        })
    }

    handleSatisfactionAccountProcess = (value) => {
        this.setState({
            newSoftwareOverview: {
                ...this.state.newSoftwareOverview,
                solution_satisfactionProcessusComptable: value.value,
                solution_satisfactionProcessusComptableObservation: value.observation
            }
        })
    }

    handleReasonsOfTheProcesses = (value) => {
        this.setState({
            newSoftwareOverview: {
            ...this.state.newSoftwareOverview,
            solution_raisonsAutomatisationProcessus: value.join(',')
          }
        })
    }

    handleAccountingProcesses = (value) => {
        
        this.setState({
            newSoftwareOverview: {
            ...this.state.newSoftwareOverview,
            solution_processusComptablePretPourAutomatisation: value.join(',')
          }
        })
    }

    handleWillingPriceToPay = (value) => {
        
        this.setState({
            newSoftwareOverview: {
            ...this.state.newSoftwareOverview,
            solution_coutMensuelPropose: value
          }
        })
    }

    handleApprehensions = (value) => {
        
        this.setState({
            newSoftwareOverview: {
            ...this.state.newSoftwareOverview,
            solution_apprehensionsDonneesComptables: value.value,
            solution_apprehensionsDonneesComptablesObservation: value.observation
          }
        })
    }

    handleIsDigitalisation = (value) => {
        this.setState({
            newSoftwareOverview: {
            ...this.state.newSoftwareOverview,
            solution_impressionParRapportDigitalisation: value.value,
            solution_impressionParRapportDigitalisationObservation: value.observation,
          }
        })
    }

    render () {
        const {
            solution_raisonsAutomatisationProcessus,
            solution_processusComptablePretPourAutomatisation,
            solution_coutMensuelPropose,
            solution_apprehensionsDonneesComptables,
            solution_apprehensionsDonneesComptablesObservation,
            solution_niveauInteretAppCloud,
            solution_satisfactionProcessusComptable,
            solution_satisfactionProcessusComptableObservation,
            solution_impressionParRapportDigitalisation,
            solution_impressionParRapportDigitalisationObservation
        } = this.state.newSoftwareOverview
        const {two,three,four,five} = ItemsStepFive
        const q1 = <span> A quel point êtes-vous satisfait de vos processus comptables actuels ?</span>
        const q2 = <span>Seriez-vous intéressé par une application Cloud qui allie intelligence artificielle et Machine Learning pour vous permettre de</span>
        const q3 = <span>Pour quelles raisons, seriez vous prêts à automatiser ces processus ? <small className="bluely"><i>(plusieurs choix sont possible)</i></small></span>
        const q4 = <span>Quels processus comptables seriez-vous prêts à automatiser ? <small className="bluely"><i>(plusieurs choix sont possible)</i></small></span>
        const interstiItems = [
            {
                index: 0,
                title: 'Très satisfait',
                value: 'Très satisfait',
                selected: false
            },
            {
                index: 1,
                title: 'Plutôt satisfait',
                value: 'Plutôt satisfait',
                selected: false
            },
            {
                index: 2,
                title: 'Pas satisfait',
                value: 'Pas satisfait',
                selected: false
            },
        ];
        const description = <>
            <div>- Scanner vos factures depuis votre smartphone et ne plus faire de saisie</div>
            <div>- Télécharger sur l’application vos relevés bancaires et ne plus avoir à rapprocher manuellement les écritures</div>
            <div>- Avoir les affectations automatiques par de l’intelligence artificielles pour réduire le travail de catégorisation comptable</div>
            <div>- Générer d’un clic les fiches de paie de tout votre personnel, faire automatiquement les déclarations d’impôts et CNPS</div>
            <div>- Suivre en temps réel l’état de vos comptes, entrées, sorties, prévisions</div>
            <div>- Générer d’un clic, à la fin de l’exercice comptable, la DSF</div>
        </>
        return(
            <Container className="step-five">
                <Row>
                    <Col>
                        <HeadTitle title={'AUTOMATISATION DES PROCESSUS COMPTABLES'} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputRateBox items={interstiItems} statement={q1} withObservation observationPlaceholder={"Qu’est-ce qui serait à améliorer selon vous ?"} getResult={this.handleSatisfactionAccountProcess} preventValue={{value: solution_satisfactionProcessusComptable, observation: solution_satisfactionProcessusComptableObservation}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputRateBox statement={q2} withDescription={description} getResult={this.handleInterestLevel} preventValue={solution_niveauInteretAppCloud}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Selecta statement={q3} items={two} getResult={this.handleReasonsOfTheProcesses} multiple  preventValue={solution_raisonsAutomatisationProcessus.split(",")} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Selecta statement={q4} items={three} getResult={this.handleAccountingProcesses} multiple  preventValue={solution_processusComptablePretPourAutomatisation.split(",")}  />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Selecta statement={'Combien seriez vous prêts à payer chaque mois pour l’utilisation de cette solution ?'} items={four} getResult={this.handleWillingPriceToPay} preventValue={solution_coutMensuelPropose}  />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Selecta 
                            statement={'Auriez vous des appréhensions quant à l’usage qui sera fait de vos données comptables ?'} 
                            items={five} getResult={this.handleApprehensions} preventValue={{value: solution_apprehensionsDonneesComptables, observation: solution_apprehensionsDonneesComptablesObservation }} 
                            withObservation
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ImputRadioBox 
                            statement={'Pensez-vous que la digitalisation est un enjeu stratégique pour votre activité ?'} 
                            getResult={this.handleIsDigitalisation} preventValue={{value: solution_impressionParRapportDigitalisation, observation: solution_impressionParRapportDigitalisationObservation}}  
                            withObservation observationConstraint={'oui'} observationPlaceholder={"Pouvez-vous dire en quoi ?"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="actions-wrapper">
                            <InputButton id={'prevfive'} name={'prevfive'} value={'PRECEDENT'} leftIcon={leftArrow} onClick={this.handlePrev} />
                            <InputButton id={'nextfive'} name={'nextfive'} value={'SOUMETTRE'} leftIcon={save} onClick={this.handleSubmit} />
                        </div>
                    </Col>
                </Row>
                
            </Container>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rootReducer: state.rootReducer
    }
}

export default connect(mapStateToProps, {setCurrentStep, setStepData})(StepFive)
