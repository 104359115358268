const ItemsStepTwo = {
    four: [
        {
            type: 'text',
            content: 'Journaliere',
            value: 'Journaliere',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: 'Loren ipsun dolor sit amet'
        },
        {
            type: 'text',
            content: 'Hebdomadaire',
            value: 'Hebdomadaire',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: 'Loren ipsun dolor sit amet'
        },
        {
            type: 'text',
            content: 'Mensuelle',
            value: 'Mensuelle',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: 'Loren ipsun dolor sit amet'
        }
    ],
    five: [
        {
            type: 'text',
            content: '1 - 50',
            value: '1 - 50',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: 'Loren ipsun dolor sit amet'
        },
        {
            type: 'text',
            content: '50 - 100',
            value: '50 - 100',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: 'Loren ipsun dolor sit amet'
        },
        {
            type: 'text',
            content: '100 - 500',
            value: '100 - 500',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: 'Loren ipsun dolor sit amet'
        },
        {
            type: 'text',
            content: 'Plus de 500',
            value: 'Plus de 500',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: 'Loren ipsun dolor sit amet'
        }
    ],
    six: [
        {
            type: 'text',
            content: 'Moins de 2 jours',
            value: 'Moins de 2 jours',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: 'Loren ipsun dolor sit amet'
        },
        {
            type: 'text',
            content: '2 - 5 jours',
            value: '2 - 5 jours',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: 'Loren ipsun dolor sit amet'
        },
        {
            type: 'text',
            content: 'Plus de 5 jours',
            value: 'Plus de 5 jours',
            params: {
                width: '',
                height: '',
            },
            selected: false,
            popOver: false,
            popOverDescription: 'Loren ipsun dolor sit amet'
        }
    ]
}

export default ItemsStepTwo