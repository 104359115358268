export const legalForms = [
    {
        type: 'text',
        content: 'ENTREPRISE INDIVIDUELLE',
        value: 'Entreprise Individuelle',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'SARL',
        value: 'sarl',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'SA',
        value: 'sa',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'SAS',
        value: 'sas',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    }
]

export const activityAreas = [
    {
        title: '',
        value: '',
    },
    {
        title: 'Commerce et réparation',
        value: 'Commerce et réparation',
    },
    {
        title: 'Hôtellerie / Restauration',
        value: 'Hôtellerie / Restauration',
    },
    {
        title: 'Services aux particuliers',
        value: 'Services aux particuliers',
    },
    {
        title: 'Services aux entreprises',
        value: 'Services aux entreprises',
    },
    {
        title: 'BTP / Construction',
        value: 'BTP / Construction',
    },
    {
        title: 'Transport',
        value: 'Transport',
    },
    {
        title: 'Industrie',
        value: 'Industrie',
    },
    {
        title: 'Immobilier',
        value: 'Immobilier',
    },
    {
        title: 'Santé / Action sociale',
        value: 'Santé / Action sociale',
    },
    {
        title: 'Agriculture',
        value: 'Agriculture',
    },
    {
        title: 'Télécommunications',
        value: 'Télécommunications',
    },
    {
        title: 'Education',
        value: 'Education',
    },
    {
        title: 'Banque / Assurance / Finances',
        value: 'Banque / Assurance / Finances',
    },
    {
        title: 'Administration',
        value: 'Administration',
    },
    {
        title: 'Conseil',
        value: 'Conseil',
    }
]

export const seniorities = [ 
    {
        type: 'text',
        content: '1',
        value: '1',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '2 - 5',
        value: '2 - 5',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '6 - 10',
        value: '6 - 10',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '11 - 50',
        value: '11 - 50',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'Plus de 50',
        value: 'Plus de 50',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
]

export const employessNumbers = [
    {
        type: 'text',
        content: '1 - 50',
        value: '1 - 50',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '51 - 100',
        value: '51 - 100',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'Plus de 100',
        value: 'Plus de 100',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
]

export const turnovers = [
    {
        type: 'text',
        content: '0 - 15 Millions',
        value: '0 - 15 Millions',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '15 - 50 Millions',
        value: '15 - 50 Millions',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'Plus de 50 Millions',
        value: 'Plus de 50 Millions',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
]

export const expenses = [
    {
        type: 'text',
        content: 'Moins de 5 000',
        value: 'Moins de 5 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '5 000 - 10 000',
        value: '5 000 - 10 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '10 000 - 20 000',
        value: '10 000 - 20 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '20 000 - 50 000',
        value: '20 000 - 50 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '50 000 - 100 000',
        value: '50 000 - 100 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'Plus de 100 000',
        value: 'Plus de 100 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
]

export const expenses_pay = [
    {
        type: 'text',
        content: 'Moins de 5 000',
        value: 'Moins de 5 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '5 000 - 10 000',
        value: '5 000 - 10 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '10 000 - 20 000',
        value: '10 000 - 20 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '20 000 - 50 000',
        value: '20 000 - 50 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '50 000 - 100 000',
        value: '50 000 - 100 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'Plus de 100 000',
        value: 'Plus de 100 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
]

export const monthlyAccountPayCosts = [
    {
        type: 'text',
        content: '5 000 - 10 000',
        value: '5 000 - 10 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '10 000 - 20 000',
        value: '10 000 - 20 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '20 000 - 50 000',
        value: '20 000 - 50 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '50 000 - 100 000',
        value: '50 000 - 100 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'Plus de 100 000',
        value: 'Plus de 100 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
]

export const annualDsfCosts = [
    {
        type: 'text',
        content: 'Moins de 200 000',
        value: 'Moins de 200 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '200 000 - 300 000',
        value: '200 000 - 300 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '300 000 - 400 000',
        value: '300 000 - 400 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '400 000 - 500 000',
        value: '400 000 - 500 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'Plus de 500 000',
        value: 'Plus de 500 000',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
]

export const companySeniorities = [
    {
        type: 'text',
        content: 'Moins d\'un an',
        value: 'Moins d\'un an',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: '1 an - 3 ans',
        value: '1 an - 3 ans',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'Plus de 3 ans',
        value: 'Plus de 3 ans',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
    {
        type: 'text',
        content: 'Je ne suis pas chef d’entreprise',
        value: 'Je ne suis pas chef d’entreprise',
        params: {
            width: '',
            height: '',
        },
        selected: false,
        popOver: false,
        popOverDescription: 'Loren ipsun dolor sit amet'
    },
]
