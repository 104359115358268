import React, {Component} from 'react'
import {Row,Col} from 'react-bootstrap'
import {Dropdown} from 'react-bootstrap'

import './InputComboBox.css'


class InputComboBox extends Component{

    constructor(props){
        super(props)

        this.state = {
            statement: this.props.statement,
            items: this.props.items,
            value: '',
            defaultChoice: ''
        }
       
    }

    handleChange = (eventKey) => {
        let ek = eventKey.split(',')
        const value = ek[1]
        const title = ek[0]
        let {getResult} = this.props
 
         this.setState({
             value,
             defaultChoice: title
         }, () => { 
            getResult(this.state.value)
         })
     }

    checkValue = () => {
        
    }

    render(){
        const {statement, items} = this.state;
        const {value} = this.props
        
        return(
            <Row className="InputComboBox">
                <Col md={7} sm={12} >
                    <div className="statement">{statement}</div>
                    <Dropdown className="">
                        <Dropdown.Toggle id="dropdown-basic" className="txt-input">
                            {value}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100">
                            {
                                items.map((item,index) => (
                                    <Dropdown.Item eventKey={[item.title,item.value]} key={index} onSelect={this.handleChange}>{item.title}</Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        )
    }
}

export default InputComboBox