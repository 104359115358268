const ItemsSteps = [
    {
        value: 1,
        label: 'Identification',
        selected: false,
        completed: false,
    },
    {
        value: 2,
        label: 'Saisie comptable',
        selected: false,
        completed: false,
    },
    {
        value: 3,
        label: 'Paie',
        selected: false,
        completed: false,
    },
    {
        value: 4,
        label: 'Utilisation internet',
        selected: false,
        completed: false,
    },
    {
        value: 5,
        label: 'La solution',
        selected: false,
        completed: false,
    },
    

]

export default ItemsSteps