import React, { Component } from 'react';
import './StepOne.css';
import { FORM_ACTIONS } from '../../../../constants/actionsNames';
import InputButton from '../../../../components/InputButton';
import {rightArrow} from '../../../../constants/imgItems'
import { Container, Row, Col } from 'react-bootstrap';
import InputBox from '../../../../components/InputBox';
import InputRadioBox from '../../../../components/ImputRadioBox';
import Selecta from '../../../../components/Selecta';
import InputComboBox from '../../../../components/InputComboBox';
import {legalForms, companySeniorities, employessNumbers, turnovers, activityAreas, monthlyAccountPayCosts, annualDsfCosts} from '../../../../constants/SelectItems';
import HeadTitle from '../../../../components/HeadTitle';
import SurveyHelper from "../../../../Helpers/SurveyHelper";

class StepOne extends Component {
    constructor (props) {
        super(props)
        this.state = {
            companyIdentification: {
                ...this.props.data
            }
        }
    }

    componentDidMount(){
        const {isAccountingFirm} = this.state.companyIdentification

        if(isAccountingFirm){
            this.handleAccountingFirm(isAccountingFirm);
        }
    }

    handleNext = () => {
        if (this.props.handleStepOne)
            this.props.handleStepOne(this.state.companyIdentification, FORM_ACTIONS.NEXT)
    }

    handleDenomination = (value) => {
        this.setState({
            companyIdentification: {
            ...this.state.companyIdentification,
            identification_denomination: value
          }
        })
    }

    handleEmail = (value) => {
        this.setState({
            companyIdentification: {
            ...this.state.companyIdentification,
            identification_email: value
          }
        })
    }

    handleLegalForm = (value) => {
        this.setState({
            companyIdentification: {
            ...this.state.companyIdentification,
            identification_formeLegale: value
          }
        })
    }

    handleAccountingFirm = (value) => {
        let SurveyHelp = new SurveyHelper()
        SurveyHelp.hideOrShowContent('.row-5',value,'non');
        SurveyHelp.hideOrShowContent('.row-8',value,'non');
        SurveyHelp.hideOrShowContent('.row-9',value,'non');

        this.setState({
            companyIdentification: {
            ...this.state.companyIdentification,
            identification_estCabinetComptable: value
          }
        });

    }

    handleActivityArea = (value) => {
        this.setState({
             companyIdentification: {
             ...this.state.companyIdentification,
             identification_secteurActivite: value
           }
        })
    }

    handleCompanySeniority = (value) => {
        this.setState({
             companyIdentification: {
             ...this.state.companyIdentification,
             identification_anciennete: value
           }
        })
    }

    handleNumberOfEmployees = (value) => {
        this.setState({
             companyIdentification: {
             ...this.state.companyIdentification,
             identification_numbreEmploye: value
           }
        })
    }

    handleTurnover = (value) => {
        this.setState({
             companyIdentification: {
             ...this.state.companyIdentification,
             identification_chiffreAffaire: value
           }
        })
    }

    handleMonthlyAccountPayCost = (value) => {
        this.setState({
            companyIdentification: {
            ...this.state.companyIdentification,
            identification_depenseMansuelleComptaPaie: value
          }
       })
    }

    handleAnnualDsfCost = (value) => {
        this.setState({
            companyIdentification: {
            ...this.state.companyIdentification,
            identification_coutAnnuelDsf: value
          }
       })
    }

    render () {
        const {identification_formeLegale,identification_anciennete,identification_numbreEmploye,identification_chiffreAffaire,identification_estCabinetComptable,identification_depenseMansuelleComptaPaie,identification_coutAnnuelDsf} = this.state.companyIdentification

        return(
            <div>
                <Container>
                    <Row>
                        <Col>
                            <HeadTitle title={'IDENTIFICATION DE L\'ENTREPRISE'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputBox statement="Raison sociale" value={this.state.companyIdentification.identification_denomination} getResult={this.handleDenomination}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputBox statement="Adresse email" type={'email'} value={this.state.companyIdentification.identification_email} getResult={this.handleEmail}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Selecta statement={'Forme juridique '} items={legalForms} getResult={this.handleLegalForm} preventValue={identification_formeLegale} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputRadioBox  statement={'Etes-vous un cabinet comptable ? '} getResult={this.handleAccountingFirm} preventValue={identification_estCabinetComptable} />
                        </Col>
                    </Row>
                    <Row className="row-5 none">
                        <Col>
                            <InputComboBox statement="Secteur d'activité" value={this.state.companyIdentification.identification_secteurActivite} items={activityAreas} getResult={this.handleActivityArea}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Selecta statement="Ancienneté de l’entreprise" items={companySeniorities} getResult={this.handleCompanySeniority}  preventValue={identification_anciennete} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Selecta statement="Taille, nombre d'employés" items={employessNumbers} getResult={this.handleNumberOfEmployees}  preventValue={identification_numbreEmploye} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Selecta statement="Chiffre d'affaire" items={turnovers} getResult={this.handleTurnover} preventValue={identification_chiffreAffaire} />
                        </Col>
                    </Row>
                    <Row className="row-8 none">
                        <Col>
                            <Selecta statement="Combien dépensez vous mensuellement pour la tenue de la comptabilité et paie au sein de votre entreprise?" 
                                items={monthlyAccountPayCosts} getResult={this.handleMonthlyAccountPayCost} preventValue={identification_depenseMansuelleComptaPaie} />
                        </Col>
                    </Row>
                    <Row className="row-9 none">
                        <Col>
                            <Selecta statement="Combien dépensez vous annuellement pour la DSF ?" items={annualDsfCosts} getResult={this.handleAnnualDsfCost} preventValue={identification_coutAnnuelDsf} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <div className="actions-wrapper">
                                <InputButton id="btnNext" name="btnNext" variant="default" value="SUIVANT" rightIcon={rightArrow} onClick={this.handleNext}/>             
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default StepOne