import React from 'react'

import './HeadTitle.css'

const HeadTitle = props => {
    
    return(
        <h3 className="head-title">
            {props.title}
        </h3>
    )
}

export default HeadTitle